import React, { useContext, memo } from 'react'
import { NoteSchema } from '../../store/notes-context'
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { CategorySchema } from '../../store/categories-context'
import {
  Draggable,
  DraggableStyle,
  DraggableStateSnapshot,
} from '@hello-pangea/dnd'
import ContextMenuContext from '../../store/context-menu-context'
import { truncate } from 'lodash'
import ModalContext from '../../store/modal-context'
import Tag from '../tags/Tag'
import Apparate from '../Apparate'
import TagOverlow from '../tags/TagOverlow'
import { getContrastYIQ } from '../../utils/colorHelpers'

interface NoteProps {
  note: NoteSchema
  index: number
  category?: CategorySchema
  isDraggingParentColumn?: boolean
  scrollElementId?: string
}

const Note: React.FC<NoteProps> = memo(function Note({
  note,
  category,
  index,
  isDraggingParentColumn,
  scrollElementId,
}) {
  const contextMenuCtx = useContext(ContextMenuContext)
  const { openMenu } = contextMenuCtx
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const menuItems = [
      {
        text: `${category?.name} Note`,
        isHeader: true,
        isDivider: true,
      },
      {
        text: 'Edit',
        action: 'editNote',
        meta: { model: note },
        icon: 'MdEdit',
        isHeader: false,
        isDivider: false,
      },
      {
        text: 'Delete',
        action: 'deleteConfirmation',
        meta: { model: note, confirmationAction: 'delete' },
        icon: 'MdDelete',
        isHeader: false,
        isDivider: false,
      },
    ]

    openMenu({
      menuItems: menuItems,
      top: e.clientY,
      left: e.clientX,
    })
  }

  const handleLeftClick = () => {
    openModal({
      modal: 'ReadOnlyNoteModal',
      meta: { model: note },
    })
  }

  const getStyle = (
    style: DraggableStyle | undefined,
    snapshot: DraggableStateSnapshot,
  ) => {
    if (
      snapshot.isDropAnimating &&
      (snapshot.draggingOver === 'sidebar' ||
        snapshot.draggingOver?.match(/newBoard-[0-9]/))
    ) {
      return {
        ...style,
        transitionDuration: '0.0000001s', // This is a hack to prevent the transition from happening
      }
    }
    return {
      ...style,
      cursor: 'pointer',
    }
  }

  const truncatedSummary = truncate(note.summary, { length: 60 })
  const visibleTags = (note?.tags || []).slice(0, 6)
  const hiddenTags = (note?.tags || []).slice(6)

  return (
    <Draggable
      key={`note-${note?.id}-${index}`} // this is important for reordering
      draggableId={`note-${note?.id}`}
      index={index}
    >
      {(provided, snapshot) => (
        <Card
          my={2}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(provided.draggableProps.style, snapshot)}
          onContextMenu={handleRightClick}
          onClick={handleLeftClick}
        >
          <Apparate
            scrollElementId={scrollElementId}
            ignoreApparate={snapshot.isDragging || isDraggingParentColumn}
          >
            <CardHeader
              p={2}
              pt={1}
              borderRadius="4px 4px 0px 0px"
              bg={category?.color || 'gray.200'}
            >
              {note.characters && note.characters.length > 0 && (
                <Center flexDirection="column">
                  <Text fontSize="xs" color={getContrastYIQ(category?.color)}>
                    Cast:
                  </Text>
                  <Box>
                    {note.characters.map((character: any) => (
                      <Tooltip
                        key={character.id}
                        label={character.name}
                        openDelay={300}
                        hasArrow
                      >
                        <Avatar
                          name={character.name}
                          src={character.avatar_url}
                          border="1px solid white"
                          iconLabel={character.name}
                          size="xs"
                        />
                      </Tooltip>
                    ))}
                  </Box>
                </Center>
              )}
            </CardHeader>

            <CardBody px={4} py={2}>
              {truncatedSummary}
            </CardBody>

            {visibleTags.length > 0 && (
              <CardFooter
                p={2}
                pt={0}
                fontSize="sm"
                flex={1}
                justify="flex-end"
              >
                <Flex flexDirection="column">
                  <Text fontSize="xs" textAlign="right">
                    Plot Links:
                  </Text>
                  <Flex mt={1}>
                    {hiddenTags.length > 0 && <TagOverlow tags={hiddenTags} />}

                    {visibleTags.map((tag, index) => (
                      <Tag
                        key={tag.id}
                        tag={tag}
                        left={4 * (visibleTags.length - 1) - 4 * index}
                        popover
                      />
                    ))}
                  </Flex>
                </Flex>
              </CardFooter>
            )}
          </Apparate>
        </Card>
      )}
    </Draggable>
  )
})

export default Note
