import React, { useState } from 'react'
import { FormType } from '../../pages/AccessPage'
import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  Button,
  Stack,
  Flex,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import axios from '../../utils/axiosConfig'

interface RequestPasswordResetFormProps {
  onFormSwap: (form: number) => void
}

interface RequestPasswordResetFormValues {
  email: string
}

const RequestPasswordResetForm: React.FC<RequestPasswordResetFormProps> = ({
  onFormSwap,
}) => {
  const [requestSent, setRequestSent] = useState(false)

  // FORM SHIT
  const initialValues: RequestPasswordResetFormValues = { email: '' }
  const validateEmail = (value: string) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address'
    }
    return error
  }

  const handleRequestPasswordReset = (
    values: { email: string },
    actions: any,
  ) => {
    axios
      .post('api/v1/timed_actions/request_password_reset', {
        email: values.email,
      })
      .finally(() => {
        actions.setSubmitting(false)
        setRequestSent(true)
      })
  }

  if (requestSent) {
    return (
      <Stack>
        <Center>
          <Heading as="h3" size="lg" mb={4}>
            Requested Password Reset Successful!
          </Heading>
        </Center>
        <Text>
          A password reset link has been sent to the email provided if it exists
          in our database.
        </Text>

        <Flex justify="flex-end" mt={8}>
          {/* RESEND BUTTON */}
          <Button
            colorScheme="teal"
            type="submit"
            mr={3}
            onClick={() => {
              setRequestSent(false)
            }}
          >
            Resend
          </Button>
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => {
              onFormSwap(FormType.LOGIN)
            }}
          >
            Go to Signin
          </Button>
        </Flex>
      </Stack>
    )
  }

  return (
    <>
      <Stack>
        <Center>
          <Heading as="h3" size="lg" mb={4}>
            Request Password Reset
          </Heading>
        </Center>
        <Text mb={8}>
          Please submit your email below and we'll send instructions about how
          to reset your password.
        </Text>
      </Stack>

      {/* FORMIK */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleRequestPasswordReset}
      >
        {(props) => (
          <Form>
            {/* EMAIL */}
            <Field name="email" validate={validateEmail}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  mb={4}
                >
                  <FormLabel>Email</FormLabel>
                  <Input {...field} type="email" placeholder="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* SUBMIT BUTTON */}
            <Flex mt={8} justify="flex-end">
              <Button
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
                mr={3}
              >
                Submit
              </Button>
              <Button
                colorScheme="teal"
                variant="outline"
                isDisabled={props.isSubmitting}
                onClick={() => {
                  onFormSwap(FormType.LOGIN)
                }}
              >
                Return to Signin
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RequestPasswordResetForm
