import React, { useContext } from 'react'
import {
  Button,
  Divider,
  Stack,
  Box,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import ModalContext from '../../store/modal-context'
import TagsContext from '../../store/tags-context'
import Tag from './Tag'
import { MdEdit, MdDelete } from 'react-icons/md'

const TagList: React.FC = () => {
  const tagsCtx = useContext(TagsContext)
  const { tags } = tagsCtx

  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  return (
    <Stack spacing={4} p={2} minH="inherit">
      <Button
        aria-label="New Plot Link"
        variant="outline"
        color="gray.500"
        borderColor="gray.400"
        minH={'40px'}
        maxW="224px"
        minW="224px"
        leftIcon={<MdAdd fontSize={'24px'} />}
        _hover={{ backgroundColor: 'gray.200' }}
        _active={{ backgroundColor: 'gray.300' }}
        borderRadius="4px"
        onClick={() => {
          openModal({ modal: 'TagModal' })
        }}
      >
        New Plot Link
      </Button>

      {tags.length > 0 && (
        <>
          <Divider orientation="horizontal" borderColor="gray.500" />
          {tags.map((tag) => (
            <Box key={tag.id}>
              <Flex mr={2} justify="space-between">
                <Flex align="center">
                  <Tag tag={tag} left={0} size={6} />
                  <Text ml={2}>{tag.name}</Text>
                </Flex>
                <Box>
                  <IconButton
                    aria-label="Save"
                    icon={<MdEdit />}
                    colorScheme="green"
                    variant="outline"
                    fontSize="14px"
                    maxH="24px"
                    minW="24px"
                    borderRadius="4px"
                    mr={1}
                    onClick={() => {
                      openModal({
                        modal: 'TagModal',
                        meta: { model: tag },
                      })
                    }}
                  />
                  <IconButton
                    aria-label="Save"
                    icon={<MdDelete />}
                    colorScheme="red"
                    variant="outline"
                    fontSize="14px"
                    maxH="24px"
                    minW="24px"
                    borderRadius="4px"
                    onClick={() => {
                      openModal({
                        modal: 'ConfirmationModal',
                        meta: { model: tag, confirmationAction: 'delete' },
                      })
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </>
      )}
    </Stack>
  )
}

export default TagList
