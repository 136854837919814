import React, { useContext } from 'react'
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Divider,
  Flex,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { CategorySchema } from '../../store/categories-context'
import { BoardSchema } from '../../store/boards-context'
import ContextMenuContext from '../../store/context-menu-context'
import ModalContext from '../../store/modal-context'
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md'
import Note from '../notes/Note'
import { Droppable } from '@hello-pangea/dnd'
import Apparate from '../Apparate'
import { getContrastYIQ } from '../../utils/colorHelpers'

interface CategoryProps {
  category: CategorySchema
  board: BoardSchema
}

const Category: React.FC<CategoryProps> = ({ category, board }) => {
  const contextMenuCtx = useContext(ContextMenuContext)
  const { openMenu } = contextMenuCtx

  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const handleRightClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const menuItems = [
      {
        text: `${category.name}`,
        isHeader: true,
        isDivider: true,
      },
      {
        text: 'New Scene',
        action: 'newNote',
        meta: { category: category, board: board },
        icon: 'MdAdd',
        isHeader: false,
        isDivider: false,
      },
      {
        text: 'Edit',
        action: 'editCategory',
        meta: { model: category },
        icon: 'MdEdit',
        isHeader: false,
        isDivider: false,
      },
      {
        text: 'Delete',
        action: 'deleteConfirmation',
        meta: { model: category, confirmationAction: 'delete' },
        icon: 'MdDelete',
        isHeader: false,
        isDivider: false,
      },
    ]

    openMenu({
      menuItems: menuItems,
      top: e.clientY,
      left: e.clientX,
    })
  }

  const boardNotes = (board.notes || []).filter(
    (note) => note.category_id === category.id,
  )

  const categoryActions = [
    {
      label: 'New Scene',
      icon: <MdAdd />,
      color: 'teal',
      handleOnClick: () =>
        openModal({
          modal: 'EditNoteModal',
          meta: { category: category, board: board },
        }),
    },
    {
      label: 'Edit Plotline',
      icon: <MdEdit />,
      color: 'teal',
      handleOnClick: () =>
        openModal({
          modal: 'CategoryModal',
          meta: { model: category },
        }),
    },
    {
      label: 'Delete Plotline',
      icon: <MdDelete />,
      color: 'red',
      handleOnClick: () =>
        openModal({
          modal: 'ConfirmationModal',
          meta: { model: category, confirmationAction: 'delete' },
        }),
    },
  ]

  return (
    <Droppable droppableId={`category-${category.id}`} type="note">
      {(provided) => (
        <AccordionItem
          border="none"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <h2>
            <AccordionButton
              minH="40px"
              borderRadius="4px"
              bg={category.color}
              color={getContrastYIQ(category.color)}
              _hover={{ cursor: 'pointer', filter: 'brightness(0.9)' }}
              _active={{ filter: 'brightness(0.8)' }}
              onContextMenu={handleRightClick}
            >
              <Box flex="1" textAlign="left">
                {category.name}
              </Box>
              <Box>
                <Tooltip
                  hasArrow
                  label="Number of available scenes"
                  openDelay={500}
                >
                  <Badge colorScheme={'null'} variant={'outline'} mb={1}>
                    {boardNotes.length}
                  </Badge>
                </Tooltip>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>

          <AccordionPanel pb={4}>
            <Flex justify="center">
              {categoryActions.map((action) => (
                <Tooltip
                  hasArrow
                  key={action.label}
                  label={action.label}
                  openDelay={500}
                >
                  <IconButton
                    aria-label={action.label}
                    icon={action.icon}
                    variant="outline"
                    colorScheme={action.color}
                    fontSize="14px"
                    maxH="24px"
                    minW="24px"
                    borderRadius="4px"
                    mx={2}
                    onClick={action.handleOnClick}
                  />
                </Tooltip>
              ))}
            </Flex>
            <Divider mt={2} mb={4} borderColor="gray.500" />
            {boardNotes.map((note, index) => (
              <Box key={note.id} my={2}>
                <Apparate>
                  <Note note={note} category={category} index={index} />
                </Apparate>
              </Box>
            ))}
            {provided.placeholder}
          </AccordionPanel>
        </AccordionItem>
      )}
    </Droppable>
  )
}

export default Category
