import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 60000,
  withCredentials: true,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message === 'Invalid JWT'
    ) {
      try {
        // Make a new request to the /refresh endpoint to fetch a new JWT token
        await axiosInstance.get('/refresh')
      } catch (refreshError) {
        // Handle the error when refreshing the token fails
        throw refreshError
      }
    }

    // Throw the original error for other cases
    throw error
  },
)

export default axiosInstance
