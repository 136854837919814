import React, { useState } from 'react'
import { FormType } from '../../pages/AccessPage'
import axios from '../../utils/axiosConfig'
import { get } from 'lodash'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi'

interface SignupFormProps {
  onFormSwap: (form: number) => void
}

interface SignupFormValues {
  email: string
  name: string
  password: string
  confirmPassword: string
}

const SignupForm: React.FC<SignupFormProps> = ({ onFormSwap }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [registrationSuccess, setRegistrationSuccess] = useState(false)

  const initialValues: SignupFormValues = {
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
  }
  const validateEmail = (value: string) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address'
    }
    return error
  }

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = 'Name is required'
    }
    return error
  }

  const validatePassword = (value: string) => {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,}$/i.test(value)
    ) {
      error =
        'Passwords must contain at least 8 characters and have one uppercase, one lowercase, one number, and one special case character.'
    }
    return error
  }

  const validateConfirmPassword = (value: string, passwordValue: any) => {
    let error
    if (value !== passwordValue) {
      error = 'Does not match password.'
    }
    return error
  }

  const handleSignup = (
    values: { email: string; name: string; password: string },
    actions: any,
  ) => {
    setErrorMessages([])
    axios
      .post('/signup', {
        user: {
          email: values.email,
          name: values.name,
          password: values.password,
        },
      })
      .then(() => {
        setRegistrationSuccess(true)
      })
      .catch((e) => {
        console.error(e)
        const messages = get(
          e,
          'response.data',
          'There was an issue with registration',
        )
        setErrorMessages(messages)
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  if (registrationSuccess) {
    // Display success message instead of the form
    return (
      <>
        <Heading as="h3" size="lg" mb={4}>
          Registration Successful!
        </Heading>
        <Button
          variant="link"
          onClick={() => {
            onFormSwap(FormType.LOGIN)
          }}
        >
          Go to Login
        </Button>
      </>
    )
  }

  return (
    <>
      <Center>
        <Heading as="h3" size="lg" mb={4}>
          Create an Account
        </Heading>
      </Center>

      <Formik initialValues={initialValues} onSubmit={handleSignup}>
        {(props) => (
          <Form>
            {/* EMAIL */}
            <Field name="email" validate={validateEmail}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  mb={4}
                >
                  <FormLabel>Email</FormLabel>
                  <Input {...field} type="email" placeholder="Email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* NAME */}
            <Field name="name" validate={validateName}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  mb={4}
                >
                  <FormLabel>Name</FormLabel>
                  <Input {...field} type="text" placeholder="Name" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* PASSWORD */}
            <Field name="password" validate={validatePassword}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  mb={4}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Toggle password visibility"
                        colorScheme="teal"
                        icon={
                          showPassword ? <PiEyeBold /> : <PiEyeClosedBold />
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* CONFIRM PASSWORD */}
            <Field
              name="confirmPassword"
              validate={(value: any) =>
                validateConfirmPassword(value, props.values.password)
              }
            >
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmPassword && form.touched.confirmPassword
                  }
                  mb={8}
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup>
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Confirm password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Toggle password visibility"
                        colorScheme="teal"
                        icon={
                          showPassword ? <PiEyeBold /> : <PiEyeClosedBold />
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.confirmPassword}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* SUBMIT */}
            <Button
              mb={1}
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
              w="full"
            >
              Signup
            </Button>

            {/* ERROR MESSAGES */}
            {errorMessages.length > 0 && (
              <Alert status="error" mt={4}>
                <Flex flexDirection="column">
                  {errorMessages.map((message: string, index: number) => (
                    <>
                      <Flex mt={index !== 0 ? 2 : 0}>
                        <AlertIcon />
                        <AlertDescription key={message}>
                          {message}
                        </AlertDescription>
                      </Flex>
                    </>
                  ))}
                </Flex>
              </Alert>
            )}

            {/* LINKS */}
            <Center mt={6} mb={4}>
              <Button
                variant="link"
                onClick={() => {
                  onFormSwap(FormType.LOGIN)
                }}
                disabled={props.isSubmitting} // Add this line to disable the button if isSubmitting is true
              >
                Have an account?
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SignupForm
