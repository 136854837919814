import React, { ReactNode, createContext } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'

// Define the shape of a note
export interface NoteSchema {
  id: number | null
  summary: string
  content?: string
  position?: any
  board_id: number | null
  category_id?: number
  tags?: any[]
  tag_ids?: number[]
  characters?: any[]
  character_ids?: number[]
}

// Define the shape of the context
interface NotesContextType {
  getNotes: (boardId: NoteSchema['board_id']) => Promise<AxiosResponse>
  createNote: (note: NoteSchema) => Promise<AxiosResponse>
  updateNote: (note: NoteSchema) => Promise<AxiosResponse>
  deleteNote: (id: NoteSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const NotesContext = createContext<NotesContextType>({
  getNotes: async () => {
    throw new Error('getNote function not implemented')
  },
  createNote: async () => {
    throw new Error('createNote function not implemented')
  },
  updateNote: async () => {
    throw new Error('updateNote function not implemented')
  },
  deleteNote: async () => {
    throw new Error('deleteNote function not implemented')
  },
})

interface NotesProviderProps {
  children?: ReactNode
}

// Create the provider component
export const NotesProvider: React.FC<NotesProviderProps> = ({ children }) => {
  const getNotes = async (boardId: NoteSchema['board_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get('api/v1/notes', {
          params: { board_id: boardId },
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createNote = async (note: NoteSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .post('api/v1/notes', { note: note })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const updateNote = (note: NoteSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .put(`api/v1/notes/${note.id}`, { note: note })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const deleteNote = (id: NoteSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`api/v1/notes/${id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <NotesContext.Provider
      value={{
        getNotes,
        createNote,
        updateNote,
        deleteNote,
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}

// Custom hook to access the notes context
export default NotesContext
