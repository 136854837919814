import React, { useContext } from 'react'
import { Flex, useMediaQuery } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { SlGraph } from 'react-icons/sl'
import { LuLink } from 'react-icons/lu'
import { FaPeopleGroup } from 'react-icons/fa6'
import ModalContext from '../../store/modal-context'
import BoardsContext from '../../store/boards-context'
import { tabs } from '../../pages/ProjectShowPage'
import SidebarAction from './SidebarAction'

interface SidebarProps {
  tabSelection: number
  setTabSelection: (tabSelection: number) => void
}

const Sidebar: React.FC<SidebarProps> = ({ tabSelection, setTabSelection }) => {
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const boardsCtx = useContext(BoardsContext)
  const { ideationBoard } = boardsCtx

  const [mdAndUp] = useMediaQuery('(min-width: 48em)')

  const handleTagSelection = (tab: number) => {
    if (tabSelection === tab) {
      setTabSelection(tabs.NONE)
    } else {
      setTabSelection(tab)
    }
  }

  return (
    <Flex
      direction={mdAndUp ? 'column' : 'row'}
      align="center"
      bg="gray.200"
      minW={mdAndUp ? '64px' : '100%'}
      w="64px"
      minH={mdAndUp ? '100%' : '64px'}
      justify={mdAndUp ? 'flex-start' : 'space-around'}
    >
      <SidebarAction
        icon={<MdAdd />}
        label={mdAndUp ? 'Scene' : ''}
        handleActionClick={() => {
          openModal({
            modal: 'EditNoteModal',
            meta: {
              board: ideationBoard,
            },
          })
        }}
      />
      <SidebarAction
        icon={<SlGraph />}
        label={mdAndUp ? 'Plotlines' : ''}
        selected={tabSelection === tabs.CATEGORIES}
        handleActionClick={() => {
          handleTagSelection(tabs.CATEGORIES)
        }}
      />
      <SidebarAction
        icon={<LuLink />}
        label={mdAndUp ? 'Plot Links' : ''}
        selected={tabSelection === tabs.TAGS}
        handleActionClick={() => {
          handleTagSelection(tabs.TAGS)
        }}
      />
      <SidebarAction
        icon={<FaPeopleGroup />}
        label={mdAndUp ? 'Cast' : ''}
        selected={tabSelection === tabs.CAST}
        handleActionClick={() => {
          handleTagSelection(tabs.CAST)
        }}
      />
    </Flex>
  )
}

export default Sidebar
