import React, { ReactNode, createContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'

export interface CharacterSchema {
  id: number | null
  name: string
  description?: string
  avatar_url?: string
  project_id: number | null
}

interface CharactersContextType {
  characters: CharacterSchema[]
  getCharacters: (
    projectId: CharacterSchema['project_id'],
  ) => Promise<AxiosResponse>
  createCharacter: (character: any) => Promise<AxiosResponse>
  updateCharacter: (character: CharacterSchema) => Promise<AxiosResponse>
  deleteCharacter: (id: CharacterSchema['id']) => Promise<AxiosResponse>
}

const CharactersContext = createContext<CharactersContextType>({
  characters: [],
  getCharacters: async () => {
    throw new Error('getCharacters function not implemented')
  },
  createCharacter: async () => {
    throw new Error('createCharacter function not implemented')
  },
  updateCharacter: async () => {
    throw new Error('updateCharacter function not implemented')
  },
  deleteCharacter: async () => {
    throw new Error('deleteCharacter function not implemented')
  },
})

interface CharactersProviderProps {
  children?: ReactNode
}

export const CharactersProvider: React.FC<CharactersProviderProps> = ({
  children,
}) => {
  const [characters, setCharacters] = useState<CharacterSchema[]>([])

  const getCharacters = async (projectId: CharacterSchema['project_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get('api/v1/characters', { params: { project_id: projectId } })
        .then((response) => {
          setCharacters(response.data.characters)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createCharacter = async (character: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      const formData = new FormData()
      formData.append('character[name]', character.name)
      formData.append('character[description]', character.description)
      formData.append('character[project_id]', character.project_id)
      if (character.avatar) {
        formData.append(
          'character[avatar]',
          character.avatar,
          character.avatar.name,
        )
      }

      axios
        .post('api/v1/characters', formData)
        .then((response) => {
          setCharacters([...characters, response.data.characters])
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const updateCharacter = (character: any) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      const formData = new FormData()
      formData.append('character[name]', character.name)
      formData.append('character[description]', character.description)
      formData.append('character[project_id]', character.project_id)
      if (character.avatar) {
        if (character.avatar === 'purge') {
          formData.append('character[avatar_purge]', 'purge')
        } else {
          formData.append(
            'character[avatar]',
            character.avatar,
            character.avatar.name,
          )
        }
      }
      axios
        .put(`api/v1/characters/${character.id}`, formData)
        .then((response) => {
          const updatedCharacter = response.data.characters
          const updatedCharacters = characters.map((c) =>
            c.id === updatedCharacter.id ? updatedCharacter : c,
          )

          setCharacters(updatedCharacters)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const deleteCharacter = (id: CharacterSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`api/v1/characters/${id}`)
        .then((response) => {
          const updatedCharacters = characters.filter((c) => c.id !== id)
          setCharacters(updatedCharacters)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <CharactersContext.Provider
      value={{
        characters,
        getCharacters,
        createCharacter,
        updateCharacter,
        deleteCharacter,
      }}
    >
      {children}
    </CharactersContext.Provider>
  )
}

export default CharactersContext
