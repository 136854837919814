import React, { useState, useContext } from 'react'
import {
  GridItem,
  Flex,
  Card,
  IconButton,
  CardBody,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { GrProjects } from 'react-icons/gr'
import { MdDelete } from 'react-icons/md'
import styles from './ProjectList.module.css'
import { useNavigate } from 'react-router-dom'
import ModalContext from '../../store/modal-context'
import { ProjectSchema } from '../../store/projects-context'

interface ProjectGridItemProps {
  project: ProjectSchema
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ProjectGridItem: React.FC<ProjectGridItemProps> = ({
  project,
  setIsLoading,
}) => {
  const [isCardHovered, setIsCardHovered] = useState(false)
  const navigate = useNavigate()

  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  return (
    <GridItem key={project.id}>
      <Flex direction="column" align="center">
        <Card
          w={146}
          h={186}
          className={styles.card}
          onMouseEnter={() => setIsCardHovered(true)}
          onMouseLeave={() => setIsCardHovered(false)}
          onClick={() => {
            navigate(`/projects/${project.id}`)
          }}
        >
          {isCardHovered && (
            <Tooltip
              label="Delete Project"
              aria-label="Delete Project"
              openDelay={500}
            >
              <IconButton
                style={{
                  position: 'absolute',
                  right: '4px',
                  top: '4px',
                }}
                aria-label="Delete"
                icon={<MdDelete />}
                minW={'32px'}
                maxH={'32px'}
                colorScheme="red"
                variant={'ghost'}
                isRound
                onClick={(e) => {
                  e.stopPropagation()
                  openModal({
                    modal: 'ConfirmationModal',
                    meta: { model: project, confirmationAction: 'delete' },
                  })
                }}
              />
            </Tooltip>
          )}
          <CardBody>
            <Flex justifyContent="center" alignItems="center" h="100%">
              <Icon color="teal" as={GrProjects} width={50} height={50} />
            </Flex>
          </CardBody>
        </Card>
        <Text mt={2} as="i" align="center">
          {project.name.length > 30 ? (
            <Tooltip label={project.name} aria-label="project name">
              <span>{project.name.substring(0, 30)}...</span>
            </Tooltip>
          ) : (
            project.name
          )}
        </Text>
      </Flex>
    </GridItem>
  )
}

export default ProjectGridItem
