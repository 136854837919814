import { useContext } from 'react'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import ModalContext from '../../store/modal-context'
import CategoryModal from './modals/CategoryModal'
import TagModal from './modals/TagModal'
import BoardModal from './modals/BoardModal'
import ReadOnlyNoteModal from './modals/ReadOnlyNoteModal'
import EditNoteModal from './modals/EditNoteModal'
import ConfirmationModal from './modals/ConfirmationModal'
import UserSettingsModal from './modals/UserSettingsModal'
import ResetPasswordModal from './modals/ResetPasswordModal'
import EditCharacterModal from './modals/EditCharacterModal'

const BaseModal: React.FC = () => {
  const ctx = useContext(ModalContext)
  const { isOpen, closeModal, modal, meta } = ctx

  const renderModal: { [key: string]: React.ReactNode } = {
    CategoryModal: <CategoryModal meta={meta} />,
    TagModal: <TagModal meta={meta} />,
    BoardModal: <BoardModal meta={meta} />,
    ReadOnlyNoteModal: <ReadOnlyNoteModal meta={meta} />,
    EditNoteModal: <EditNoteModal meta={meta} />,
    ConfirmationModal: <ConfirmationModal meta={meta} />,
    UserSettingsModal: <UserSettingsModal />,
    ResetPasswordModal: <ResetPasswordModal />,
    EditCharacterModal: <EditCharacterModal meta={meta} />,
  }

  const modalSizes: { [key: string]: string } = {
    EditNoteModal: '2xl',
    EditCharacterModal: '2xl',
    ReadOnlyNoteModal: '3xl',
    ConfirmationModal: 'xl',
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size={modalSizes[modal] || 'md'}
      >
        <ModalOverlay />
        <ModalContent>{renderModal[modal]}</ModalContent>
      </Modal>
    </>
  )
}

export default BaseModal
