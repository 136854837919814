import React, { useContext } from 'react'
import {
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Box,
  Icon,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import ModalContext from '../../../store/modal-context'
import Colorful from '@uiw/react-color-colorful'
import TagsContext from '../../../store/tags-context'
import ProjectsContext from '../../../store/projects-context'
import { Select } from 'chakra-react-select'
import { ICONS } from '../../../utils/icons'
import { startCase } from 'lodash'

interface TagModalProps {
  meta?: any
}

const TagModal: React.FC<TagModalProps> = ({ meta }) => {
  const tag = meta?.model

  const modalCtx = useContext(ModalContext)
  const { setSaving, closeModal } = modalCtx
  const projectsCtx = useContext(ProjectsContext)
  const { project } = projectsCtx
  const tagsCtx = useContext(TagsContext)
  const { createTag, updateTag } = tagsCtx

  const handleSaveTag = (
    values: { name: string; color: string; tag: string },
    actions: any,
  ) => {
    if (project) {
      const tagData = {
        id: tag?.id || null,
        name: values.name,
        project_id: tag?.project_id || project?.id,
        color: values.color,
        icon: values.tag,
      }

      setSaving(true)

      const saveFn = tag?.id ? updateTag : createTag

      saveFn(tagData)
        .then(() => {
          closeModal()
        })
        .catch((error) => {
          console.error('Error creating tag:', error)
          actions.setSubmitting(false)
        })
    }
  }

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = 'Name is required'
    }
    return error
  }

  const parseKey = (key: string) => {
    return key
      .replace(/^(Gi)/g, '')
      .replace(/[0-9]+[A-Z]/g, '')
      .replace(/[0-9]/g, '')
  }

  const tagOptions = Object.keys(ICONS).map((key) => ({
    value: key,
    label: (
      <Flex alignItems="center">
        <Icon as={ICONS[key]} w={4} h={4} mr={2} />
        {startCase(parseKey(key))}
      </Flex>
    ),
  }))

  const defaultTag = tag?.icon
    ? tagOptions.find((t) => t.value === tag.icon)
    : tagOptions[Math.floor(Math.random() * tagOptions.length)]

  return (
    <>
      <ModalHeader>{tag?.id ? 'Update' : 'Create'} a Plot Link</ModalHeader>
      <ModalCloseButton />
      <Formik
        initialValues={{
          name: tag?.name || '',
          color: tag?.color || '#E3AEAA',
          tag: tag?.icon || defaultTag?.value,
        }}
        onSubmit={handleSaveTag}
      >
        {(props) => (
          <Form>
            <ModalBody>
              <Field name="name" validate={validateName}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter tag name"
                      {...field}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="tag">
                {({ field }: { field: any }) => (
                  <FormControl zIndex={2}>
                    <FormLabel>Icon</FormLabel>
                    <Select
                      options={tagOptions}
                      defaultValue={defaultTag}
                      onChange={(selected) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: selected?.value,
                          },
                        })
                      }}
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="color">
                {({ field }: { field: any }) => (
                  <FormControl mt={4}>
                    <FormLabel>Color</FormLabel>
                    <Box mb={4} w="28px" h="28px" bg={field.value} />
                    <Colorful
                      color={field.value}
                      disableAlpha
                      onChange={(color) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: color.hex,
                          },
                        })
                      }}
                    />
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={props.isSubmitting}
                colorScheme="teal"
                mr={3}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={props.isSubmitting}
                variant="outline"
                onClick={closeModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default TagModal
