import { Box } from '@chakra-ui/react'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

interface ApparateProps {
  scrollElementId?: string
  ignoreApparate?: boolean
  children: ReactNode
}

const Apparate: React.FC<ApparateProps> = ({
  scrollElementId,
  ignoreApparate,
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When the element is visible
        if (entry.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      },
      {
        root: scrollElementId
          ? document.getElementById(scrollElementId) || null
          : null, // scrollable area. Null defaults to viewport.
        rootMargin: '300px', // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0, // visible amount of item shown in relation to root
      },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }

    // eslint-disable-next-line
  }, [])

  const [minHeight, setMinHeight] = useState<string>('72px')

  useEffect(() => {
    if (ref.current && isVisible && minHeight === '72px') {
      const childrenHeight = ref.current.getBoundingClientRect().height
      setMinHeight(`${childrenHeight}px`)
    }
  }, [isVisible, children, minHeight])

  return (
    <Box ref={ref} minH={minHeight}>
      {(ignoreApparate || isVisible) && children}
    </Box>
  )
}

export default Apparate
