import {
  Gi3DHammer,
  GiAbdominalArmor,
  GiAllForOne,
  GiAnvil,
  GiApothecary,
  GiBarracksTent,
  GiCampingTent,
  GiCastle,
  GiCauldron,
  GiCowled,
  GiCrown,
  GiCrownedSkull,
  GiFire,
  GiFlatPawPrint,
  GiFluffyWing,
  GiGemPendant,
  GiGhost,
  GiHandcuffs,
  GiHealingShield,
  GiHeartWings,
  GiIsland,
  GiJeweledChalice,
  GiJesterHat,
  GiLeechingWorm,
  GiLightningStorm,
  GiLips,
  GiLovers,
  GiMartini,
  GiMinotaur,
  GiMoneyStack,
  GiMoon,
  GiMusicalNotes,
  GiMushroomGills,
  GiNinjaHead,
  GiNinjaVelociraptor,
  GiOpenBook,
  GiOpenChest,
  GiPadlock,
  GiPartyHat,
  GiPegasus,
  GiPentacle,
  GiPirateCaptain,
  GiPirateHook,
  GiPlesiosaurus,
  GiPointyHat,
  GiPoison,
  GiPoisonBottle,
  GiPokerHand,
  GiPoliceOfficerHead,
  GiPotionBall,
  GiPrayer,
  GiPrettyFangs,
  GiPunch,
  GiQueenCrown,
  GiQuillInk,
  GiRaining,
  GiRaven,
  GiReceiveMoney,
  GiRobe,
  GiRobotGolem,
  GiRollingBomb,
  GiSaberTooth,
  GiSailboat,
  GiSandsOfTime,
  GiScarWound,
  GiScrollUnfurled,
  GiSeaDragon,
  GiSnowflake2,
  GiSpellBook,
  GiStarsStack,
  GiStopwatch,
  GiTwoCoins,
  GiUnicorn,
  GiUpgrade,
  GiVampireCape,
  GiVerticalBanner,
  GiWerewolf,
  GiWesternHat,
  GiWhirlwind,
  GiWoodenDoor,
  GiXylophone,
  GiYinYang,
  GiZeppelin,
  GiZeusSword,
} from 'react-icons/gi'
import { IconType } from 'react-icons/lib'

export const ICONS: { [key: string]: IconType } = {
  Gi3DHammer,
  GiAbdominalArmor,
  GiAllForOne,
  GiAnvil,
  GiApothecary,
  GiBarracksTent,
  GiCampingTent,
  GiCastle,
  GiCauldron,
  GiCowled,
  GiCrown,
  GiCrownedSkull,
  GiFire,
  GiFlatPawPrint,
  GiFluffyWing,
  GiGemPendant,
  GiGhost,
  GiHandcuffs,
  GiHealingShield,
  GiHeartWings,
  GiIsland,
  GiJeweledChalice,
  GiJesterHat,
  GiLeechingWorm,
  GiLightningStorm,
  GiLips,
  GiLovers,
  GiMartini,
  GiMinotaur,
  GiMoneyStack,
  GiMoon,
  GiMusicalNotes,
  GiMushroomGills,
  GiNinjaHead,
  GiNinjaVelociraptor,
  GiOpenBook,
  GiOpenChest,
  GiPadlock,
  GiPartyHat,
  GiPegasus,
  GiPentacle,
  GiPirateCaptain,
  GiPirateHook,
  GiPlesiosaurus,
  GiPointyHat,
  GiPoison,
  GiPoisonBottle,
  GiPokerHand,
  GiPoliceOfficerHead,
  GiPotionBall,
  GiPrayer,
  GiPrettyFangs,
  GiPunch,
  GiQueenCrown,
  GiQuillInk,
  GiRaining,
  GiRaven,
  GiReceiveMoney,
  GiRobe,
  GiRobotGolem,
  GiRollingBomb,
  GiSaberTooth,
  GiSailboat,
  GiSandsOfTime,
  GiScarWound,
  GiScrollUnfurled,
  GiSeaDragon,
  GiSnowflake2,
  GiSpellBook,
  GiStarsStack,
  GiStopwatch,
  GiTwoCoins,
  GiUnicorn,
  GiUpgrade,
  GiVampireCape,
  GiVerticalBanner,
  GiWerewolf,
  GiWesternHat,
  GiWhirlwind,
  GiWoodenDoor,
  GiXylophone,
  GiYinYang,
  GiZeppelin,
  GiZeusSword,
}
