import React, { ReactNode, createContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'
import { NoteSchema } from './notes-context'

// Define the shape of a board
export interface BoardSchema {
  id: number | null
  name: string
  position?: any
  project_id: number | null
  notes?: NoteSchema[]
  note_ids?: number[]
}

// Define the shape of the context
interface BoardsContextType {
  ideationBoard: BoardSchema | null
  setIdeationBoard: (board: BoardSchema) => void
  boards: BoardSchema[]
  setBoards: (boards: BoardSchema[]) => void
  getBoards: (projectId: BoardSchema['project_id']) => Promise<AxiosResponse>
  createBoard: (board: BoardSchema) => Promise<AxiosResponse>
  updateBoard: (
    board: BoardSchema,
    newPosition?: { before?: number | null; after?: number | null },
  ) => Promise<AxiosResponse>
  deleteBoard: (id: BoardSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const BoardsContext = createContext<BoardsContextType>({
  ideationBoard: null,
  setIdeationBoard: () => {},
  boards: [],
  setBoards: () => {},
  getBoards: async () => {
    throw new Error('getBoard function not implemented')
  },
  createBoard: async () => {
    throw new Error('createBoard function not implemented')
  },
  updateBoard: async () => {
    throw new Error('updateBoard function not implemented')
  },
  deleteBoard: async () => {
    throw new Error('deleteBoard function not implemented')
  },
})

interface BoardsProviderProps {
  children?: ReactNode
}

// Create the provider component
export const BoardsProvider: React.FC<BoardsProviderProps> = ({ children }) => {
  const [ideationBoard, setIdeationBoard] =
    useState<BoardsContextType['ideationBoard']>(null)
  const [boards, setBoards] = useState<BoardsContextType['boards']>([])

  const getBoards = async (projectId: BoardSchema['project_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .get('api/v1/boards', {
          params: { project_id: projectId },
        })
        .then((response) => {
          setBoards(response.data.boards)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const createBoard = async (board: BoardSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .post('api/v1/boards', { board: board })
        .then((response) => {
          const newBoard = response.data.boards
          const newBoards = [...boards]

          newBoards.splice(newBoard.position - 1, 0, newBoard)

          setBoards(newBoards)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const updateBoard = (board: BoardSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .put(`api/v1/boards/${board.id}`, { board: board })
        .then((response) => {
          setBoards((prevBoards) => {
            return prevBoards.map((b) =>
              b.id === board.id ? { ...b, ...response.data.boards } : b,
            )
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const deleteBoard = (id: BoardSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`api/v1/boards/${id}`)
        .then((response) => {
          const oldBoardNotes = boards.find((b) => b.id === id)?.notes
          if (oldBoardNotes && ideationBoard?.notes) {
            ideationBoard.notes.push(...oldBoardNotes)
          }
          const updatedBoards = boards.filter((c) => c.id !== id)
          setBoards(updatedBoards)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <BoardsContext.Provider
      value={{
        ideationBoard,
        setIdeationBoard,
        boards,
        setBoards,
        getBoards,
        createBoard,
        updateBoard,
        deleteBoard,
      }}
    >
      {children}
    </BoardsContext.Provider>
  )
}

// Custom hook to access the boards context
export default BoardsContext
