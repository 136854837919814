import React, { ReactNode, createContext, useEffect, useState } from 'react'

// Define the shape of your modal context
interface ModalContextProps {
  isOpen: boolean
  closeModal: () => void
  saving: boolean
  setSaving: (saving: boolean) => void
  modal: string
  meta: any
  openModal: (props: { modal: string; meta?: any }) => void
}

// Create the modal context
const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  closeModal: () => {},
  saving: false,
  setSaving: (saving: boolean) => {},
  modal: '',
  meta: null,
  openModal: (props: { modal: string; meta?: any }) => {},
})

interface ModalProviderProps {
  children?: ReactNode
}

// Create the modal context provider
export const ModalContextProvider: React.FC<ModalProviderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [meta, setMeta] = useState(null)
  const [modal, setModal] = useState('')
  const [saving, setSaving] = useState(false)

  const openModal = (props: { modal: string; meta?: any }) => {
    setMeta(props?.meta || null)
    setModal(props?.modal || '')
    setIsOpen(true)
  }

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const closeModal = () => {
    if (saving) return

    setIsOpen(false)

    const timeout = setTimeout(() => {
      setSaving(false)
    }, 300)
    setTimeoutId(timeout)
  }

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [timeoutId])

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        closeModal,
        saving,
        setSaving,
        modal,
        meta,
        openModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalContext
