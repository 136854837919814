import React, { useContext } from 'react'
import NavBar from '../components/nav-bar/NavBar'
import BaseModal from '../components/dialogs/BaseModal'
import ContextMenu from '../components/dialogs/ContextMenu'
import ContextMenuContext from '../store/context-menu-context'

interface MainLayoutProps {
  children: React.ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const contextMenuCtx = useContext(ContextMenuContext)
  const { isOpen } = contextMenuCtx

  return (
    <>
      <NavBar />
      <BaseModal />
      {isOpen && <ContextMenu />}

      <main
        style={{
          minHeight: 'calc(100vh - 57px)',
          maxHeight: 'calc(100vh - 57px)',
        }}
      >
        {children}
      </main>
    </>
  )
}

export default MainLayout
