import React, { useContext, useState, useRef, useEffect } from 'react'
import { FiArrowLeft, FiSettings, FiLogOut } from 'react-icons/fi'
import { MdEdit, MdCheck, MdClose } from 'react-icons/md'
import { RiUserFill } from 'react-icons/ri'
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import AuthContext from '../../store/auth-context'
import PlotOnBanner from '../../assets/images/plot-on-banner.png'
import PlotOnLogo from '../../assets/illustrations/plot-on-logo.svg'
import ProjectsContext from '../../store/projects-context'
import ModalContext from '../../store/modal-context'
import { useNavigate } from 'react-router-dom'

const NavBar: React.FC = () => {
  const authContext = useContext(AuthContext)
  const projectsContext = useContext(ProjectsContext)
  const { project } = projectsContext
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const [inputWidth, setInputWidth] = useState<number>(0)
  const [saving, setSaving] = useState(false)

  const nameInputRef = useRef<HTMLInputElement>(null)
  const [nameValue, setNameValue] = useState(project?.name || '')

  const [mdAndUp] = useMediaQuery('(min-width: 48em)')

  useEffect(() => {
    if (nameInputRef.current) {
      const width = nameInputRef.current.scrollWidth
      if (width > window.innerWidth - 110) {
        setInputWidth(window.innerWidth - 110)
      } else {
        setInputWidth(width)
      }
    } else {
      setInputWidth(mdAndUp ? 300 : 100)
    }

    // eslint-disable-next-line
  }, [nameValue, isEditingName])

  const handleEditName = () => {
    setIsEditingName(true)
    setNameValue(project?.name || '')

    setTimeout(() => {
      nameInputRef.current?.focus()
    }, 100)
  }

  const handleSaveName = () => {
    if (saving) return

    setSaving(true)
    const updatedProject = {
      ...project,
      name: nameValue,
      id: project?.id || null, // Ensure id is either number or null
    }
    projectsContext
      .updateProject(updatedProject)
      .catch((error) => {
        console.error('Error updating project name:', error)
      })
      .finally(() => {
        setIsEditingName(false)
        setSaving(false)
      })
  }

  const imgSrc = mdAndUp ? PlotOnBanner : PlotOnLogo
  const imgStyles = mdAndUp
    ? {
        width: '128px',
        height: '40px',
      }
    : {
        width: '24px',
        height: '24px',
      }

  return (
    <Box
      borderBottom="1px solid #c6c6c6"
      p={2}
      w="100%"
      top={0}
      zIndex={100}
      bgColor="white"
    >
      <Flex align="center" justify="space-between">
        {project && (
          <>
            <img style={imgStyles} src={imgSrc} alt="Plot-on Banner" />

            <Flex
              alignItems="center"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              position="relative"
              px={10}
            >
              {isEditingName && (
                <>
                  <InputGroup>
                    <Input
                      ref={nameInputRef}
                      variant={'flushed'}
                      value={nameValue}
                      onChange={(e) => setNameValue(e.target.value)}
                      size={mdAndUp ? 'md' : 'sm'}
                      style={{
                        fontSize: mdAndUp ? '20px' : '16px',
                        fontFamily: 'Merriweather, serif',
                        width: inputWidth ? `${inputWidth}px` : 'auto',
                      }}
                      disabled={saving}
                      pr="60px"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSaveName()
                        } else if (e.key === 'Escape') {
                          setIsEditingName(false)
                        }
                      }}
                    />
                    <InputRightElement w="56px">
                      <IconButton
                        aria-label="Save"
                        icon={<MdCheck />}
                        colorScheme="green"
                        variant="outline"
                        fontSize="14px"
                        maxH="24px"
                        minW="24px"
                        borderRadius="4px"
                        mr={1}
                        onClick={handleSaveName}
                        isLoading={saving}
                        isDisabled={saving}
                      />
                      <IconButton
                        aria-label="Save"
                        icon={<MdClose />}
                        colorScheme="red"
                        variant="outline"
                        fontSize="14px"
                        maxH="24px"
                        minW="24px"
                        borderRadius="4px"
                        onClick={() => {
                          setIsEditingName(false)
                        }}
                        isDisabled={saving}
                      />
                    </InputRightElement>
                  </InputGroup>
                </>
              )}
              {!isEditingName && (
                <Flex pos="relative" justify="center">
                  <Heading
                    mb={0}
                    size={mdAndUp ? 'md' : 'sm'}
                    textAlign="center"
                  >
                    {project?.name}
                  </Heading>
                  {isHovered && (
                    <Tooltip
                      hasArrow
                      label="Edit the project's name"
                      openDelay={500}
                    >
                      <IconButton
                        aria-label="Edit the project's name"
                        icon={<MdEdit />}
                        variant="outline"
                        colorScheme="teal"
                        fontSize="14px"
                        maxH="24px"
                        minW="24px"
                        borderRadius="4px"
                        mx={2}
                        onClick={handleEditName}
                        style={{
                          position: 'absolute',
                          right: '-48px',
                          top: '0px',
                        }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              )}
            </Flex>
          </>
        )}

        <Menu>
          <MenuButton
            as={IconButton}
            rounded="full"
            colorScheme="teal"
            maxH={mdAndUp ? '40px' : '24px'}
            minW={mdAndUp ? '40px' : '24px'}
          >
            <Icon mt="4px" as={RiUserFill} />
          </MenuButton>
          <MenuList minWidth="50px">
            {project && (
              <MenuItem
                icon={<FiArrowLeft />}
                onClick={() => {
                  navigate('/projects')
                }}
              >
                Back to Projects
              </MenuItem>
            )}
            <MenuItem
              icon={<FiSettings />}
              onClick={() => {
                openModal({ modal: 'UserSettingsModal' })
              }}
            >
              User Settings
            </MenuItem>
            <MenuItem icon={<FiLogOut />} onClick={authContext.onLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  )
}

export default NavBar
