import React, { createContext, useState, useEffect, ReactNode } from 'react'
import axios from '../utils/axiosConfig'
import { Center, Spinner } from '@chakra-ui/react'

interface AuthContextProps {
  authUser: any
  setAuthUser: (user: any) => void
  onLogout: () => void
  onLogin: (email: string, password: string) => Promise<void>
}

const AuthContext = createContext<AuthContextProps>({
  authUser: null,
  setAuthUser: (user: any) => {},
  onLogout: () => {},
  onLogin: (email, password) => {
    return Promise.resolve()
  },
})

interface AuthContextProviderProps {
  children?: ReactNode
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = (
  props,
) => {
  const [authUser, setAuthUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const checkLoggedInStatus = () => {
      axios
        .get('/session')
        .then((res) => {
          if (res.status === 200) {
            setAuthUser(res.data.user)
          }
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    checkLoggedInStatus()

    // eslint-disable-next-line
  }, [])

  const logoutHandler = () => {
    axios
      .delete('/session')
      .then((res) => {
        setAuthUser(null)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const loginHandler = (email: string, password: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      axios
        .post('/session', { user: { email, password } })
        .then((res) => {
          setAuthUser(res.data.user)
          resolve(res.data) // Resolve with the response data if needed
        })
        .catch((error) => {
          console.error(error)
          reject(error) // Reject with the error object
        })
    })
  }

  if (isLoading) {
    return (
      <Center h={'100vh'}>
        <Spinner color="teal" thickness="4px" h={'100px'} w={'100px'} />
      </Center>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        authUser: authUser,
        setAuthUser: setAuthUser,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext
