import React, { useEffect, useRef } from 'react'
import {
  Flex,
  Tag as ChakraTag,
  TagLeftIcon,
  TagLabel,
  Popover,
  PopoverTrigger,
  PopoverBody,
  PopoverContent,
  useDisclosure,
  Portal,
  PopoverArrow,
  Box,
} from '@chakra-ui/react'
import { TagSchema } from '../../store/tags-context'
import { MdAdd } from 'react-icons/md'
import Tag from './Tag'

interface TagOverflowProps {
  tags: TagSchema[]
}

const TagOverlow: React.FC<TagOverflowProps> = ({ tags }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const tagOverflowMenuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // add listener for user clicking outside of tag overflow menu
    const handleClick = (e: MouseEvent) => {
      if (
        tagOverflowMenuRef.current &&
        !tagOverflowMenuRef.current.contains(e.target as Node)
      ) {
        onClose()
      }
    }
    document.addEventListener('click', handleClick)

    // clean up listener function to avoid memory leaks
    return () => {
      document.removeEventListener('click', handleClick)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Popover isOpen={isOpen} isLazy>
        <PopoverTrigger>
          <Flex position="absolute" right="136px" bottom="10px">
            <ChakraTag
              size={'sm'}
              variant="outline"
              borderRadius="full"
              colorScheme="teal"
              minW={5}
              maxH={5}
              minH={5}
              p={1}
              _hover={{
                backgroundColor: 'teal.50',
              }}
              _active={{ backgroundColor: 'teal.100' }}
              onClick={(e) => {
                e.stopPropagation()
                onToggle()
              }}
            >
              <TagLeftIcon as={MdAdd} mr={0} />
              <TagLabel minW="fit-content" mr={1}>
                {tags.length}
              </TagLabel>
            </ChakraTag>
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            minW="50px"
            w="fit-content"
            maxW="186px"
            bg="gray.400"
            ref={tagOverflowMenuRef}
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <PopoverArrow bg="gray.400" />
            <PopoverBody>
              <Flex direction="row" wrap="wrap">
                {tags.map((tag) => (
                  <Box key={tag.id} m={1}>
                    <Tag key={tag.id} tag={tag} popover />
                  </Box>
                ))}
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

export default TagOverlow
