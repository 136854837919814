import React from 'react'
import { Flex, IconButton, Text } from '@chakra-ui/react'

interface SidebarActionProps {
  label: string
  icon: any
  selected?: boolean
  handleActionClick: () => void
}

const SidebarAction: React.FC<SidebarActionProps> = ({
  label,
  icon,
  selected,
  handleActionClick,
}) => {
  return (
    <Flex direction="column" align="center" my="2">
      <IconButton
        icon={icon}
        aria-label="Sidebar Action"
        variant="outline"
        bgColor={selected ? 'gray.300' : 'transparent'}
        color="gray.500"
        borderColor="gray.400"
        maxW={'40px'}
        minH={'40px'}
        fontSize={'24px'}
        _hover={{ backgroundColor: 'gray.300' }}
        _active={{ backgroundColor: 'gray.400' }}
        borderRadius="4px"
        onClick={handleActionClick}
      />
      <Text fontSize="xs" color="gray.500" textAlign="center" mt="1" px="1">
        {label}
      </Text>
    </Flex>
  )
}

export default SidebarAction
