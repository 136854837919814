import React, { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import AuthContext from '../../store/auth-context'

const PrivateRoutes: React.FC = () => {
  const ctx = useContext(AuthContext)

  return ctx.authUser ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes
