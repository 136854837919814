import React, { useState } from 'react'
import PlotOnLogo from '../assets/illustrations/plot-on-logo.svg'
import LoginForm from '../components/forms/LoginForm'
import SignupForm from '../components/forms/SignupForm'
import { Center, Flex, Box, Card } from '@chakra-ui/react'
import RequestPasswordResetForm from '../components/forms/RequestPasswordResetForm'

export enum FormType {
  LOGIN,
  SIGNUP,
  REQUEST_PASSWORD_RESET,
}

const LoginPage: React.FC = () => {
  const [activeForm, setActiveForm] = useState(FormType.LOGIN)

  const handleFormSwap = (form: number) => {
    setActiveForm(form)
  }

  const renderForm = () => {
    switch (activeForm) {
      case FormType.LOGIN:
        return <LoginForm onFormSwap={handleFormSwap} />
      case FormType.SIGNUP:
        return <SignupForm onFormSwap={handleFormSwap} />
      case FormType.REQUEST_PASSWORD_RESET:
        return <RequestPasswordResetForm onFormSwap={handleFormSwap} />
      default:
        return null
    }
  }

  return (
    <div style={{ height: '100vh', backgroundColor: '#E3AEAA' }}>
      <Center minH="100%" backgroundColor="#E3AEAA">
        <Card
          maxW="500px"
          w="90%"
          p={{ base: 4, md: 10 }}
          m={{ base: 4, md: 6 }}
        >
          <Flex justifyContent={'center'}>
            <Box w="400px">
              <Center mb={6}>
                <img
                  src={PlotOnLogo}
                  alt="Plot-on Logo"
                  style={{ width: '50px' }}
                />
              </Center>
              {renderForm()}
            </Box>
          </Flex>
        </Card>
      </Center>
    </div>
  )
}

export default LoginPage
