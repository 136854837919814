import React, { useState, useContext, useEffect } from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'
import CategoriesContext from '../../store/categories-context'
import CategoryList from '../categories/CategoryList'
import TagList from '../tags/TagList'
import CharacterList from '../characters/CharacterList'
import { Droppable } from '@hello-pangea/dnd'
import { tabs } from '../../pages/ProjectShowPage'

interface SidebarExtensionProps {
  tabSelection: number
}

const SidebarExtension: React.FC<SidebarExtensionProps> = ({
  tabSelection,
}) => {
  const [categoriesLoaded, setCategoriesLoaded] = useState(false)

  const categoriesCtx = useContext(CategoriesContext)
  const { categoriesLoading } = categoriesCtx
  useEffect(() => {
    if (!categoriesLoading) {
      setCategoriesLoaded(true)
    }
  }, [categoriesLoading])

  return (
    <Flex
      as="nav"
      direction="column"
      bg="gray.100"
      minW="240px"
      w="240px"
      maxW="240px"
      transition="width 0.3s, min-width 0.3s"
    >
      <Droppable droppableId="sidebar" type="note">
        {(provided) => (
          <Flex
            direction="column"
            h="100%"
            overflowY="scroll"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {/* SIDEBAR CONTENT */}
            <Skeleton h="100%" overflowY="scroll" isLoaded={categoriesLoaded}>
              {tabSelection === tabs.CATEGORIES && <CategoryList />}
              {tabSelection === tabs.TAGS && <TagList />}
              {tabSelection === tabs.CAST && <CharacterList />}
            </Skeleton>
          </Flex>
        )}
      </Droppable>
    </Flex>
  )
}

export default SidebarExtension
