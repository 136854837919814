import React, { ReactElement, useContext } from 'react'
import {
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import ModalContext from '../../../store/modal-context'
import CategoriesContext from '../../../store/categories-context'
import BoardsContext from '../../../store/boards-context'
import NotesContext, { NoteSchema } from '../../../store/notes-context'
import TagsContext from '../../../store/tags-context'
import ProjectsContext from '../../../store/projects-context'
import CharactersContext from '../../../store/characters-context'

interface CategoryModalProps {
  meta: {
    model: {
      id: number | null
      name: string
      model_type: string
    }
    confirmationAction: string
  }
}

const CategoryModal: React.FC<CategoryModalProps> = ({ meta }) => {
  const { model, confirmationAction } = meta || {}

  const modalCtx = useContext(ModalContext)
  const { saving, setSaving, closeModal } = modalCtx

  const categoriesCtx = useContext(CategoriesContext)
  const { deleteCategory } = categoriesCtx

  const boardsCtx = useContext(BoardsContext)
  const { boards, setBoards, ideationBoard, setIdeationBoard, deleteBoard } =
    boardsCtx

  const notesCtx = useContext(NotesContext)
  const { deleteNote } = notesCtx

  const tagsCtx = useContext(TagsContext)
  const { deleteTag } = tagsCtx

  const charactersCtx = useContext(CharactersContext)
  const { deleteCharacter } = charactersCtx

  const projectsCtx = useContext(ProjectsContext)
  const { deleteProject } = projectsCtx

  type ConfirmationDetailsMapType = {
    [action: string]: {
      [modelType: string]: {
        action: (id: number | null) => Promise<any>
        header: string
        body: ReactElement<any>
        callback?: () => void
      }
    }
  }

  const confirmationDetailsMap: ConfirmationDetailsMapType = {
    delete: {
      Board: {
        action: deleteBoard,
        header: 'Delete a Storyboard',
        body: (
          <>
            You are attempting to delete the {model?.name} storyboard.{' '}
            <b>
              Deleting this storyboard will move all it's scenes to their
              plotlines in the sidebar.
            </b>{' '}
            Are you sure you want to delete this storyboard?
          </>
        ),
      },
      Category: {
        action: deleteCategory,
        header: 'Delete a Plotline',
        body: (
          <>
            You are attempting to delete the {model?.name} plotline.{' '}
            <b>
              Deleting this plotline will also delete all associated scenes.
            </b>{' '}
            Are you sure you want to delete this plotline?
          </>
        ),
      },
      Note: {
        action: deleteNote,
        header: 'Delete a Scene',
        body: <>Are you sure you want to delete this scene?</>,
        callback: () => {
          const note = model as unknown as NoteSchema
          const isOnIdeationBoard = note?.board_id === ideationBoard?.id
          const board = isOnIdeationBoard
            ? ideationBoard
            : boards.find((board) => board.id === note?.board_id)
          if (board?.notes) {
            const newNotes = board.notes.filter((n) => n.id !== note.id)
            board.notes = newNotes
          }

          if (isOnIdeationBoard) {
            setIdeationBoard(ideationBoard)
          } else {
            setBoards([...boards])
          }
        },
      },
      Project: {
        action: deleteProject,
        header: 'Delete a Project',
        body: (
          <>
            You are attempting to delete the <i>{model?.name}</i> project.{' '}
            <b>
              Deleting this project will also delete all associated storyboards,
              plotlines, plot links, and scenes.
            </b>{' '}
            Are you sure you want to delete this project?
          </>
        ),
      },
      Tag: {
        action: deleteTag,
        header: 'Delete a Plot Link',
        body: (
          <>
            Are you sure you want to delete the {model?.name} plot link?
            Deleting this plot link will remove it from all associated scenes.
          </>
        ),
      },
      Character: {
        action: deleteCharacter,
        header: 'Delete a Character',
        body: (
          <>
            Are you sure you want to delete the character {model?.name}?
            Deleting this character will remove it from this project and all
            associated scenes.
          </>
        ),
      },
    },
  }

  const confirmationDetails =
    confirmationDetailsMap[confirmationAction][model?.model_type]

  const handleConfirmationAction = () => {
    const action = confirmationDetails.action

    if (action) {
      setSaving(true)

      action(model?.id)
        .then(() => {
          if (confirmationDetails.callback) {
            confirmationDetails.callback()
          }
          closeModal()
        })
        .catch((error: Error) => {
          console.error('Error:', error)
          setSaving(false)
        })
    } else {
      console.error(
        'Invalid confirmation action:',
        confirmationAction,
        model?.model_type,
      )
    }
  }

  return (
    <>
      <ModalHeader>
        {confirmationDetails.header || 'Confirmation Modal'}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {/* {confirmationDetails.body || 'Are you sure you want to delete this?'} */}
        {confirmationDetails.body || 'Are you sure you want to delete this?'}
      </ModalBody>
      <ModalFooter>
        <Button
          isLoading={saving}
          colorScheme={confirmationAction === 'delete' ? 'red' : 'teal'}
          mr={3}
          onClick={handleConfirmationAction}
        >
          {confirmationAction === 'delete' ? 'Delete' : 'Save'}
        </Button>
        <Button isDisabled={saving} variant="outline" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

export default CategoryModal
