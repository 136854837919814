import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Text, Container, Divider, Heading, Icon } from '@chakra-ui/react'
import ContextMenuContext from '../../store/context-menu-context'
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md'

const iconMap: { [key: string]: any } = {
  MdAdd,
  MdEdit,
  MdDelete,
}

const ContextMenu: React.FC = () => {
  const contextMenuCtx = useContext(ContextMenuContext)
  const { menuItems, top, left, ctxMenuFns, closeMenu } = contextMenuCtx
  const contextMenuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    // add listener for user clicking outside of context menu
    const handleClick = (e: MouseEvent) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(e.target as Node)
      ) {
        closeMenu()
      }
    }
    document.addEventListener('click', handleClick)

    // clean up listener function to avoid memory leaks
    return () => {
      document.removeEventListener('click', handleClick)
    }

    // eslint-disable-next-line
  }, [])

  const [parsedTop, setParsedTop] = useState(top)
  useEffect(() => {
    if (contextMenuRef.current) {
      const { height } = contextMenuRef.current.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      if (top + height > viewportHeight) {
        setParsedTop(viewportHeight - height - 20)
      } else {
        setParsedTop(top)
      }
    }
  }, [top])

  return (
    <>
      <Container
        ref={contextMenuRef}
        pos="absolute"
        top={`${parsedTop - 55}px`}
        left={`${left + 10}px`}
        bg="white"
        borderRadius="4px"
        boxShadow="md"
        p={0}
        w="150px"
        maxW="250px"
        zIndex="popover"
      >
        {menuItems.map((item, index) => (
          <Box
            key={index}
            px={2}
            my={2}
            bg="white"
            _hover={
              item.action
                ? { filter: 'brightness(0.9)', cursor: 'pointer' }
                : {}
            }
            _active={
              item.action
                ? { filter: 'brightness(0.8)', cursor: 'pointer' }
                : {}
            }
          >
            {item.isHeader && (
              <Heading as="h3" size="sm" mb={2}>
                {item.text}
              </Heading>
            )}

            {item.isDivider && <Divider />}

            {!item.isHeader && !item.isDivider && (
              <Text
                onClick={() => {
                  closeMenu()
                  item.action && ctxMenuFns[item.action](item?.meta)
                }}
                display={'flex'}
                alignItems={'center'}
              >
                {item.icon && <Icon as={iconMap[item.icon]} mr={2} />}
                {item.text}
              </Text>
            )}
          </Box>
        ))}
      </Container>
    </>
  )
}

export default ContextMenu
