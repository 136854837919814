import React, { useContext } from 'react'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md'
import ModalContext from '../../store/modal-context'
import CharactersContext from '../../store/characters-context'

const CharacterList: React.FC = () => {
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const charactersCtx = useContext(CharactersContext)
  const { characters } = charactersCtx

  return (
    <Stack spacing={4} p={2} minH="inherit">
      <Button
        aria-label="New character"
        variant="outline"
        color="gray.500"
        borderColor="gray.400"
        minH={'40px'}
        maxW="224px"
        minW="224px"
        leftIcon={<MdAdd fontSize={'24px'} />}
        _hover={{ backgroundColor: 'gray.200' }}
        _active={{ backgroundColor: 'gray.300' }}
        borderRadius="4px"
        onClick={() => {
          openModal({ modal: 'EditCharacterModal' })
        }}
      >
        New Character
      </Button>

      {characters.length > 0 && (
        <>
          <Divider orientation="horizontal" borderColor="gray.500" />
          {characters.map((character) => (
            <Box key={character.id}>
              <Flex mr={2} justify="space-between">
                <Flex align="center">
                  <Avatar
                    name={character.name}
                    src={character.avatar_url}
                    size="sm"
                  />
                  <Text ml={2}>{character.name}</Text>
                </Flex>

                <Box>
                  <IconButton
                    aria-label="Save"
                    icon={<MdEdit />}
                    colorScheme="green"
                    variant="outline"
                    fontSize="14px"
                    maxH="24px"
                    minW="24px"
                    borderRadius="4px"
                    mr={1}
                    onClick={() => {
                      openModal({
                        modal: 'EditCharacterModal',
                        meta: { model: character },
                      })
                    }}
                  />
                  <IconButton
                    aria-label="Save"
                    icon={<MdDelete />}
                    colorScheme="red"
                    variant="outline"
                    fontSize="14px"
                    maxH="24px"
                    minW="24px"
                    borderRadius="4px"
                    onClick={() => {
                      openModal({
                        modal: 'ConfirmationModal',
                        meta: {
                          model: character,
                          confirmationAction: 'delete',
                        },
                      })
                    }}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </>
      )}
    </Stack>
  )
}

export default CharacterList
