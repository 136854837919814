import React, { ReactNode, createContext, useState } from 'react'

interface DragContextProps {
  dragging: boolean
  setDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const DragContext = createContext<DragContextProps>({
  dragging: false,
  setDragging: () => {},
})

interface DragProviderProps {
  children?: ReactNode
}

export const DragProvider: React.FC<DragProviderProps> = (props) => {
  const [dragging, setDragging] = useState(false)

  return (
    <DragContext.Provider value={{ dragging, setDragging }}>
      {props.children}
    </DragContext.Provider>
  )
}

export default DragContext
