import React, { useState, useContext } from 'react'
import {
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Input,
  Button,
} from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
import { startCase } from 'lodash'
import axios from '../../../../utils/axiosConfig'
import AuthContext from '../../../../store/auth-context'

interface UserInfoFormProps {
  attr: any
  value: any
  validation: (value: any) => string | undefined
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({
  attr,
  value,
  validation,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [newValue, setNewValue] = useState(value)

  const authCtx = useContext(AuthContext)
  const { authUser, setAuthUser } = authCtx

  let validationError = validation(newValue)

  const handleSaveUserAttr = () => {
    setIsSaving(true)

    axios
      .put(`api/v1/users/${authUser.id}`, { user: { [attr]: newValue } })
      .then((res) => {
        setAuthUser(res.data.users)
      })
      .catch((e) => {
        console.error(e)
      })
      .finally(() => {
        setEditMode(false)
        setIsHovered(false)
      })
  }

  const attrForm = (
    <>
      <Input
        value={newValue}
        onChange={(e) => setNewValue(e.target.value)}
        size="sm"
        variant="outline"
        borderColor="gray.300"
        minW="100%"
        mb={validationError ? 1 : 2}
        isInvalid={!!validationError}
        isDisabled={isSaving}
        _hover={{ borderColor: 'gray.400' }}
        _focus={{ borderColor: 'teal.500' }}
      />
      {validationError && (
        <Text fontSize="xs" color="red">
          {validationError}
        </Text>
      )}
      <Flex justify="flex-end">
        <Button
          size="sm"
          colorScheme="teal"
          onClick={handleSaveUserAttr}
          ml={2}
          isLoading={isSaving}
          isDisabled={!!validationError}
        >
          Save
        </Button>
        <Button
          size="sm"
          variant="outline"
          isDisabled={isSaving}
          onClick={() => {
            setNewValue(value)
            setEditMode(false)
            setIsHovered(false)
          }}
          ml={2}
        >
          Cancel
        </Button>
      </Flex>
    </>
  )

  return (
    <Flex
      mb={4}
      align="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box w="100%">
        <Heading as="h3" size="xs" color="gray.500">
          {startCase(attr)}
        </Heading>
        {!editMode && <Text fontSize={18}>{value}</Text>}

        {editMode && attrForm}
      </Box>

      {isHovered && !editMode && (
        <IconButton
          aria-label="Edit"
          icon={<MdEdit />}
          variant="outline"
          colorScheme="teal"
          fontSize="14px"
          maxH="24px"
          minW="24px"
          borderRadius="4px"
          onClick={() => setEditMode(true)}
        />
      )}
    </Flex>
  )
}

export default UserInfoForm
