import React, { useContext } from 'react'
import {
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import ModalContext from '../../../store/modal-context'
import BoardsContext from '../../../store/boards-context'
import ProjectsContext from '../../../store/projects-context'
import { Field, Formik, Form } from 'formik'

interface BoardModalProps {
  meta?: any
}

const BoardModal: React.FC<BoardModalProps> = ({ meta }) => {
  const board = meta?.model

  const modalCtx = useContext(ModalContext)
  const { closeModal } = modalCtx
  const projectsCtx = useContext(ProjectsContext)
  const { project } = projectsCtx
  const boardsCtx = useContext(BoardsContext)
  const { createBoard, updateBoard } = boardsCtx

  const handleSaveBoard = (values: { name: string }, actions: any) => {
    if (project) {
      const boardData = {
        id: board?.id || null,
        name: values.name,
        project_id: board?.project_id || project?.id,
        position: board?.position,
      }

      const saveFn = board?.id ? updateBoard : createBoard

      saveFn(boardData)
        .then(() => {
          closeModal()
        })
        .catch((error) => {
          console.error('Error creating board:', error)
          actions.setSubmitting(false)
        })
    }
  }

  const validateName = (value: string) => {
    let error
    if (value.length > 100) {
      error = 'Name must be at most 100 characters'
    }
    return error
  }

  return (
    <>
      <ModalHeader>{board?.id ? 'Update' : 'Create'} a Storyboard</ModalHeader>
      <ModalCloseButton />
      <Formik
        initialValues={{
          name: board?.name || '',
        }}
        onSubmit={handleSaveBoard}
      >
        {(props) => (
          <Form>
            <ModalBody>
              <Field name="name" validate={validateName}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel>
                      Name <i>(Optional)</i>
                    </FormLabel>
                    <Input
                      type="text"
                      placeholder="What do you want to call this chapter?"
                      {...field}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={props.isSubmitting}
                colorScheme="teal"
                mr={3}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={props.isSubmitting}
                variant="outline"
                onClick={closeModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default BoardModal
