import { useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import styles from './FileUpload.module.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css'

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFilePoster,
)

const FileUpload = ({ fileUrls = [], onChange }) => {
  const [files, setFiles] = useState([])

  const handleInit = () => {
    const psuedoFile = fileUrls.map((url) => ({
      source: 1234,
      options: {
        type: 'local',
        file: {
          name: 'my-avatar.png',
          size: 0,
          type: 'image',
        },
        metadata: {
          poster: url,
        },
      },
    }))

    setFiles(psuedoFile)
  }

  const handleOnPrepareFile = (file, blob) => {
    setFiles([file])
    onChange(blob)
  }

  const handleOnRemoveFile = () => {
    setFiles([])
    onChange('purge')
  }

  return (
    <>
      <FilePond
        files={files}
        allowMultiple={false}
        instantUpload={false}
        acceptedFileTypes="image/png, image/jpeg"
        allowImagePreview={true}
        allowImageCrop={true}
        allowImageResize={true}
        allowImageTransform={true}
        allowFilePoster={true}
        allowReplace={true}
        allowImageExifOrientation={true}
        imagePreviewHeight={170}
        imageCropAspectRatio="1:1"
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        stylePanelLayout="compact circle"
        styleLoadIndicatorPosition="center bottom"
        styleProgressIndicatorPosition="right bottom"
        styleButtonRemoveItemPosition="center bottom"
        styleButtonProcessItemPosition="right bottom"
        credits=""
        className={styles['avatar-upload']}
        labelIdle="<svg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' height='2em' width='2em' xmlns='http://www.w3.org/2000/svg' style='color: gray;' color='gray'><path fill='none' d='M0 0h24v24H0z'></path><path d='M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z'></path></svg>"
        oninit={handleInit}
        onpreparefile={handleOnPrepareFile}
        onremovefile={handleOnRemoveFile}
      />
    </>
  )
}

export default FileUpload
