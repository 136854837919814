import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormType } from '../../pages/AccessPage'
import AuthContext from '../../store/auth-context'
import { get } from 'lodash'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi'

interface LoginFormProps {
  onFormSwap: (form: number) => void
}

interface LoginFormValues {
  email: string
  password: string
}

const LoginForm: React.FC<LoginFormProps> = ({ onFormSwap }) => {
  const [loginFailed, setLoginFailed] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  // FORM SHIT
  const initialValues: LoginFormValues = { email: '', password: '' }
  const validateEmail = (value: string) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address'
    }
    return error
  }

  const handleLogin = (
    values: { email: string; password: string },
    actions: any,
  ) => {
    authContext
      .onLogin(values.email, values.password)
      .then(() => {
        navigate('/projects')
      })
      .catch((e) => {
        console.error(e)
        const message = get(
          e,
          'response.data.message',
          'There was an issue with login',
        )
        setLoginFailed(message)
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  return (
    <>
      <Center>
        <Heading as="h3" size="lg" mb={4}>
          Sign in
        </Heading>
      </Center>

      {/* FORMIK */}
      <Formik initialValues={initialValues} onSubmit={handleLogin}>
        {(props) => (
          <Form>
            {/* EMAIL */}
            <Field name="email" validate={validateEmail}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  mb={4}
                >
                  <FormLabel>Email</FormLabel>
                  <Input {...field} type="email" placeholder="email" />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* PASSWORD */}
            <Field name="password">
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                  mb={8}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="Search database"
                        colorScheme="teal"
                        icon={
                          showPassword ? <PiEyeBold /> : <PiEyeClosedBold />
                        }
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* LOGIN BUTTON */}
            <Button
              mb={1}
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
              w="full"
            >
              Login
            </Button>
            <Flex justify="end">
              <Button
                variant="link"
                size={'sm'}
                disabled={props.isSubmitting}
                onClick={() => {
                  onFormSwap(FormType.REQUEST_PASSWORD_RESET)
                }}
              >
                Forgot password?
              </Button>
            </Flex>

            {/* ERROR */}
            {loginFailed && (
              <Alert status="error" mt={4}>
                <AlertIcon />
                <AlertDescription>{loginFailed}</AlertDescription>
              </Alert>
            )}

            {/* LINKS */}
            <Center mt={6}>
              <Text mb={0}>
                Don't have an account?{' '}
                <Button
                  variant="link"
                  disabled={props.isSubmitting}
                  onClick={() => {
                    onFormSwap(FormType.SIGNUP)
                  }}
                >
                  Signup
                </Button>
              </Text>
            </Center>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LoginForm
