import React, { useEffect, useContext } from 'react'
import MainLayout from '../layouts/MainLayout'
import ProjectList from '../components/project-list/ProjectList'
import { Stack, Flex } from '@chakra-ui/react'
import PlotOnBanner from '../assets/images/plot-on-banner.png'
import ProjectsContext from '../store/projects-context'

const ProjectsPage: React.FC = () => {
  const ctx = useContext(ProjectsContext)

  useEffect(() => {
    ctx.resetProject()

    // eslint-disable-next-line
  }, [])

  return (
    <MainLayout>
      <Stack spacing={0} align="center" minH={'inherit'} bg="#E3AEAA">
        <Flex w="100%" justifyContent={'center'} py={8} bg="#fafafa">
          <img
            src={PlotOnBanner}
            alt="Plot-on Banner"
            style={{ width: '400px' }}
          />
        </Flex>
        <Flex w={'100%'} pt={10} justifyContent={'center'} alignItems={'start'}>
          <ProjectList />
        </Flex>
      </Stack>
    </MainLayout>
  )
}

export default ProjectsPage
