import React, { useContext } from 'react'
import { Divider, Button, Stack, Accordion } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import ModalContext from '../../store/modal-context'
import CategoriesContext from '../../store/categories-context'
import BoardsContext from '../../store/boards-context'
import Category from './Category'

const CategoryList: React.FC = () => {
  const modalCtx = useContext(ModalContext)
  const { openModal } = modalCtx

  const boardsCtx = useContext(BoardsContext)
  const { ideationBoard } = boardsCtx

  const categoriesCtx = useContext(CategoriesContext)
  const { categories } = categoriesCtx

  return (
    <Stack spacing={4} p={2} minH="inherit">
      <Button
        aria-label="New plotline"
        variant="outline"
        color="gray.500"
        borderColor="gray.400"
        minH={'40px'}
        maxW="224px"
        minW="224px"
        leftIcon={<MdAdd fontSize={'24px'} />}
        _hover={{ backgroundColor: 'gray.200' }}
        _active={{ backgroundColor: 'gray.300' }}
        borderRadius="4px"
        onClick={() => {
          openModal({ modal: 'CategoryModal' })
        }}
      >
        New Plotline
      </Button>

      {categories.length > 0 && (
        <>
          <Divider orientation="horizontal" borderColor="gray.500" />
          {categories.map(
            (category) =>
              ideationBoard && (
                <Accordion allowMultiple key={category.id}>
                  <Category category={category} board={ideationBoard} />
                </Accordion>
              ),
          )}
        </>
      )}
    </Stack>
  )
}

export default CategoryList
