import React, { useState, useContext } from 'react'
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import axios from '../../../utils/axiosConfig'
import ModalContext from '../../../store/modal-context'
import AuthContext from '../../../store/auth-context'

const ResetPasswordModal: React.FC = () => {
  const [sendingLink, setSendingLink] = useState(false)
  const [linkSent, setLinkSent] = useState(false)

  const modalCtx = useContext(ModalContext)
  const { closeModal } = modalCtx
  const authCtx = useContext(AuthContext)
  const { authUser } = authCtx

  const handleRequestPasswordReset = () => {
    setSendingLink(true)

    axios
      .post('api/v1/timed_actions/request_password_reset', {
        email: authUser.email,
      })
      .finally(() => {
        setSendingLink(false)
        setLinkSent(true)
      })
  }

  return (
    <>
      <ModalHeader>
        {linkSent ? 'Success!' : 'Request Reset Password'}
      </ModalHeader>
      <ModalCloseButton />
      {!linkSent && (
        <ModalBody>
          You are attempting to reset your password. A password reset link will
          be sent to the email associated with your account. Are you sure you
          want to reset your password?
        </ModalBody>
      )}
      {linkSent && (
        <ModalBody>
          A password reset link has been sent to the email associated with your
          account. Please check your email.
        </ModalBody>
      )}
      <ModalFooter>
        {!linkSent && (
          <Button
            colorScheme="teal"
            mr={3}
            isLoading={sendingLink}
            onClick={handleRequestPasswordReset}
          >
            Reset Password
          </Button>
        )}
        <Button variant="outline" isDisabled={sendingLink} onClick={closeModal}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  )
}

export default ResetPasswordModal
