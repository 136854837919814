import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PlotOnLogo from '../assets/illustrations/plot-on-logo.svg'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Card,
  Flex,
  Box,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { PiEyeBold, PiEyeClosedBold } from 'react-icons/pi'
import axios from '../utils/axiosConfig'
import { get } from 'lodash'

interface PasswordResetPageProps {}

interface PasswordResetPageValues {
  password: string
  confirmPassword: string
}

const PasswordResetPage: React.FC<PasswordResetPageProps> = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [passwordResetFailed, setPasswordResetFailed] = useState(false)
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
  const navigate = useNavigate()
  const { token } = useParams()

  // FORM SHIT
  const initialValues: PasswordResetPageValues = {
    password: '',
    confirmPassword: '',
  }

  const validatePassword = (value: string) => {
    let error
    if (!value) {
      error = 'Password is required'
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,}$/i.test(value)
    ) {
      error =
        'Passwords must contain at least 8 characters and have one uppercase, one lowercase, one number, and one special case character.'
    }
    return error
  }

  const validateConfirmPassword = (value: string, passwordValue: any) => {
    let error
    if (value !== passwordValue) {
      error = 'Does not match password.'
    }
    return error
  }

  const handlePasswordReset = (
    values: { password: string; confirmPassword: string },
    actions: any,
  ) => {
    const params = {
      secret: token,
      args: {
        new_password: values.password,
        confirm_password: values.confirmPassword,
      },
    }

    axios
      .post('api/v1/timed_actions/perform_timed_action', params)
      .then(() => {
        setPasswordResetSuccess(true)
      })
      .catch((error) => {
        // returns appropriate error message
        const errorMessage = get(
          error,
          'response.data.errors[0].detail',
          'An error has occured',
        )

        setPasswordResetFailed(errorMessage)
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  const successMessage = () => {
    return (
      <>
        <Heading as="h3" size="lg" mb={4} textAlign="center">
          Password Reset Successful!
        </Heading>
        <Text mt={8}>
          Click the link below to sign in using your new password.
        </Text>
        <Center mt={8}>
          <Button
            variant="link"
            onClick={() => {
              navigate('/login')
            }}
          >
            Go to Login
          </Button>
        </Center>
      </>
    )
  }

  const passwordResetForm = () => {
    return (
      <>
        <Center>
          <Heading as="h3" size="lg" mb={4}>
            Reset Password
          </Heading>
        </Center>

        {/* FORMIK */}
        <Formik initialValues={initialValues} onSubmit={handlePasswordReset}>
          {(props) => (
            <Form>
              {/* PASSWORD */}
              <Field name="password" validate={validatePassword}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}
                    mb={8}
                  >
                    <FormLabel>New Password</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="New Password"
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Toggle password visibility"
                          colorScheme="teal"
                          icon={
                            showPassword ? <PiEyeBold /> : <PiEyeClosedBold />
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* CONFIRM PASSWORD */}
              <Field
                name="confirmPassword"
                validate={(value: any) =>
                  validateConfirmPassword(value, props.values.password)
                }
              >
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={
                      form.errors.confirmPassword &&
                      form.touched.confirmPassword
                    }
                    mb={8}
                  >
                    <FormLabel>Confirm Password</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Toggle password visibility"
                          colorScheme="teal"
                          icon={
                            showPassword ? <PiEyeBold /> : <PiEyeClosedBold />
                          }
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      {form.errors.confirmPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Flex justify="flex-end">
                {/* SUBMIT BUTTON */}
                <Button
                  colorScheme="teal"
                  isLoading={props.isSubmitting}
                  type="submit"
                  mr={3}
                >
                  Submit
                </Button>
                <Button
                  colorScheme="teal"
                  variant="outline"
                  isDisabled={props.isSubmitting}
                  onClick={() => navigate('/login')}
                >
                  Return to Signin
                </Button>
              </Flex>
              {/* ERROR */}
              {passwordResetFailed && (
                <Alert status="error" mt={4}>
                  <AlertIcon />
                  <AlertDescription>{passwordResetFailed}</AlertDescription>
                </Alert>
              )}
            </Form>
          )}
        </Formik>
      </>
    )
  }

  return (
    <div style={{ height: '100vh', backgroundColor: '#E3AEAA' }}>
      <Center minH="100%" backgroundColor="#E3AEAA">
        <Card maxW="500px" w="80%" p={10} m={6}>
          <Flex justifyContent={'center'}>
            <Box w="400px">
              <Center mb={6}>
                <img
                  src={PlotOnLogo}
                  alt="Plot-on Logo"
                  style={{ width: '50px' }}
                />
              </Center>

              {passwordResetSuccess ? successMessage() : passwordResetForm()}
            </Box>
          </Flex>
        </Card>
      </Center>
    </div>
  )
}

export default PasswordResetPage
