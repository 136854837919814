import React, { useContext } from 'react'
import {
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import ModalContext from '../../../store/modal-context'
import Colorful from '@uiw/react-color-colorful'
import CategoriesContext from '../../../store/categories-context'
import ProjectsContext from '../../../store/projects-context'

interface CategoryModalProps {
  meta?: any
}

const CategoryModal: React.FC<CategoryModalProps> = ({ meta }) => {
  const category = meta?.model

  const modalCtx = useContext(ModalContext)
  const { setSaving, closeModal } = modalCtx
  const projectsCtx = useContext(ProjectsContext)
  const { project } = projectsCtx
  const categoriesCtx = useContext(CategoriesContext)
  const { createCategory, updateCategory } = categoriesCtx

  const handleSaveCategory = (
    values: { name: string; color: string },
    actions: any,
  ) => {
    if (project) {
      const categoryData = {
        id: category?.id || null,
        name: values.name,
        project_id: category?.project_id || project?.id,
        color: values.color,
      }

      setSaving(true)

      const saveFn = category?.id ? updateCategory : createCategory

      saveFn(categoryData)
        .then(() => {
          closeModal()
        })
        .catch((error) => {
          console.error('Error creating category:', error)
          actions.setSubmitting(false)
        })
    }
  }

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = 'Name is required'
    } else if (value.length > 50) {
      error = 'Name must be at most 50 characters'
    }
    return error
  }

  return (
    <>
      <ModalHeader>{category?.id ? 'Update' : 'Create'} a Category</ModalHeader>
      <ModalCloseButton />
      <Formik
        initialValues={{
          name: category?.name || '',
          color: category?.color || '#E3AEAA',
        }}
        onSubmit={handleSaveCategory}
      >
        {(props) => (
          <Form>
            <ModalBody>
              <Field name="name" validate={validateName}>
                {({ field, form }: { field: any; form: any }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter category name"
                      {...field}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="color">
                {({ field }: { field: any }) => (
                  <FormControl mt={4}>
                    <FormLabel>Color</FormLabel>
                    <Box mb={4} w="28px" h="28px" bg={field.value} />
                    <Colorful
                      color={field.value}
                      disableAlpha
                      onChange={(color) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: color.hex,
                          },
                        })
                      }}
                    />
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={props.isSubmitting}
                colorScheme="teal"
                mr={3}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={props.isSubmitting}
                variant="outline"
                onClick={closeModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CategoryModal
