import React, { ReactNode, createContext, useState } from 'react'
import axios from '../utils/axiosConfig'
import { AxiosResponse } from 'axios'

// Define the shape of a tag
export interface TagSchema {
  id: number | null
  name: string
  color: string
  icon: string
  project_id: number | null
}

// Define the shape of the context
interface TagsContextType {
  tags: TagSchema[]
  getTags: (projectId: TagSchema['project_id']) => Promise<AxiosResponse>
  tagActionInProgress: boolean
  setTagActionInProgress: React.Dispatch<React.SetStateAction<boolean>>
  createTag: (tag: TagSchema) => Promise<AxiosResponse>
  updateTag: (tag: TagSchema) => Promise<AxiosResponse>
  deleteTag: (id: TagSchema['id']) => Promise<AxiosResponse>
}

// Create the initial context
const TagsContext = createContext<TagsContextType>({
  tags: [],
  getTags: async () => {
    throw new Error('getTags function not implemented')
  },
  tagActionInProgress: false,
  setTagActionInProgress: () => {
    throw new Error('setTagActionInProgress function not implemented')
  },
  createTag: async () => {
    throw new Error('createTag function not implemented')
  },
  updateTag: async () => {
    throw new Error('updateTag function not implemented')
  },
  deleteTag: async () => {
    throw new Error('deleteTag function not implemented')
  },
})

interface TagsProviderProps {
  children?: ReactNode
}

// Create the provider component
export const TagsProvider: React.FC<TagsProviderProps> = ({ children }) => {
  const [tags, setTags] = useState<TagSchema[]>([])
  const [tagActionInProgress, setTagActionInProgress] = useState(false)

  const getTags = async (projectId: TagSchema['project_id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      setTagActionInProgress(true)

      axios
        .get('api/v1/tags', { params: { project_id: projectId } })
        .then((response) => {
          setTags(response.data.tags)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          setTagActionInProgress(false)
        })
    })
  }

  const createTag = async (tag: TagSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .post('api/v1/tags', tag)
        .then((response) => {
          setTags([...tags, response.data.tags])
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const updateTag = (tag: TagSchema) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .put(`api/v1/tags/${tag.id}`, tag)
        .then((response) => {
          const updatedTag = response?.data?.tags

          const updatedTags = tags.map((c) => {
            if (c.id === updatedTag.id) {
              return updatedTag
            }
            return c
          })
          setTags(updatedTags)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const deleteTag = (id: TagSchema['id']) => {
    return new Promise<AxiosResponse>((resolve, reject) => {
      axios
        .delete(`api/v1/tags/${id}`)
        .then((response) => {
          const updatedTags = tags.filter((c) => c.id !== id)
          setTags(updatedTags)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  return (
    <TagsContext.Provider
      value={{
        tags,
        getTags,
        tagActionInProgress,
        setTagActionInProgress,
        createTag,
        updateTag,
        deleteTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

// Custom hook to access the tags context
export default TagsContext
