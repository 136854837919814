import {
  Box,
  Button,
  Flex,
  Heading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react'
import React, { useState, useContext } from 'react'
import ModalContext from '../../../store/modal-context'
import AuthContext from '../../../store/auth-context'
import UserInfoForm from './user-settings-modal/UserInfoForm'

const UserSettingsModal: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false)

  const modalCtx = useContext(ModalContext)
  const { openModal, closeModal } = modalCtx

  const authCtx = useContext(AuthContext)
  const { authUser } = authCtx

  const validateEmail = (value: string) => {
    let error
    if (!value) {
      error = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = 'Invalid email address'
    }
    return error
  }

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = 'Name is required'
    }
    return error
  }

  return (
    <>
      <ModalHeader>User Settings</ModalHeader>
      <ModalBody>
        <UserInfoForm
          attr="email"
          value={authUser?.email}
          validation={validateEmail}
        />
        <UserInfoForm
          attr="name"
          value={authUser?.name}
          validation={validateName}
        />

        <Flex
          mb={4}
          align="center"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box w="100%">
            <Heading as="h3" size="xs" color="gray.500">
              Password
            </Heading>
            <Text fontSize={24}>**********</Text>
          </Box>
          {isHovered && (
            <Button
              size="sm"
              colorScheme="teal"
              variant="link"
              onClick={() => {
                openModal({
                  modal: 'ResetPasswordModal',
                })
              }}
            >
              Reset password?
            </Button>
          )}
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outline"
          onClick={() => {
            closeModal()
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

export default UserSettingsModal
