import React, { useContext, useEffect, useState, useCallback } from 'react'
import {
  Flex,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Tooltip,
  useDisclosure,
  Portal,
  PopoverArrow,
  PopoverHeader,
  Spinner,
  Center,
  Text,
  Box,
  Divider,
} from '@chakra-ui/react'
import { ICONS } from '../../utils/icons'
import axios from '../../utils/axiosConfig'
import { NoteSchema } from '../../store/notes-context'
import CategoriesContext from '../../store/categories-context'
import { getContrastYIQ } from '../../utils/colorHelpers'

interface TagProps {
  tag: any
  left?: number | null
  size?: number
  popover?: boolean
}

const Tag: React.FC<TagProps> = ({ tag, left, size = 4, popover = false }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [notes, setNotes] = useState([])
  const [initializing, setInitializing] = useState(true)

  const categoriesCtx = useContext(CategoriesContext)
  const { categories } = categoriesCtx

  const fetchNotes = useCallback(() => {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/notes/find_notes_by_tag', { params: { tag_id: tag.id } })
        .then((res) => {
          setNotes(res.data.notes)
          resolve(res.data.notes)
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }, [tag.id])

  useEffect(() => {
    if (isOpen) {
      fetchNotes().then((res) => {
        setInitializing(false)
      })
    }
  }, [isOpen, fetchNotes])

  return (
    <Popover
      placement="right"
      isOpen={isOpen}
      isLazy
      offset={[0, left ? left : 1]}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Box>
          <Tooltip key={tag.name} label={tag.name} openDelay={300} hasArrow>
            <Flex
              backgroundColor={tag.color}
              borderRadius="full"
              minW={size + 2}
              maxW={size + 2}
              minH={size + 2}
              border="1px solid white"
              justify="center"
              align="center"
              pos="relative"
              left={left ? left + 'px' : undefined}
              _hover={
                popover
                  ? { filter: 'brightness(0.9)', cursor: 'pointer' }
                  : undefined
              }
              _active={popover ? { filter: 'brightness(0.8)' } : undefined}
              onClick={(e: any) => {
                if (!popover) return

                e.stopPropagation()
                onToggle()
              }}
            >
              <Icon
                as={ICONS[tag.icon]}
                color={getContrastYIQ(tag.color)}
                minW={size}
                minH={size}
              />
            </Flex>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          maxW="200px"
          maxH="260px"
          onClick={(e: any) => e.stopPropagation()}
        >
          <PopoverArrow />
          <PopoverHeader>Associated Scenes</PopoverHeader>
          <PopoverBody px={0} overflowY="scroll">
            {initializing && (
              <Center my={4}>
                <Spinner color="teal" thickness="4px" h="60px" w="60px" />
              </Center>
            )}

            {!initializing && notes.length === 0 && (
              <Center>No notes found</Center>
            )}

            {!initializing &&
              notes.map((note: NoteSchema, index) => (
                <Box key={note.id}>
                  <Flex
                    key={note.id}
                    py={2}
                    px={3}
                    justify="flex-start"
                    align="flex-start"
                    cursor="pointer"
                    _hover={{ backgroundColor: '#f0f0f0' }}
                    onClick={() => {
                      onClose()
                      document
                        .getElementById(`note-${note.id}`)
                        ?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'center',
                        })
                    }}
                  >
                    <Box
                      minW="16px"
                      h="16px"
                      borderRadius="4px"
                      backgroundColor={
                        categories.find(
                          (category) => category.id === note.category_id,
                        )?.color
                      }
                      mr={2}
                    />
                    <Text fontSize="xs">{note.summary}</Text>
                  </Flex>

                  {index !== notes.length - 1 && (
                    <Box px={2}>
                      <Divider />
                    </Box>
                  )}
                </Box>
              ))}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default Tag
