import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom'
import AccessPage from './pages/AccessPage'
import ProjectsPage from './pages/ProjectsPage'
import ProjectShowPage from './pages/ProjectShowPage'
import PrivateRoutes from './components/auth/PrivateRoute'
import { ProjectsProvider } from './store/projects-context'
import { CategoriesProvider } from './store/categories-context'
import { NotesProvider } from './store/notes-context'
import { ModalContextProvider } from './store/modal-context'
import { ContextMenuProvider } from './store/context-menu-context'
import { BoardsProvider } from './store/boards-context'
import { DragProvider } from './store/drag-context'
import { TagsProvider } from './store/tags-context'
import { CharactersProvider } from './store/characters-context'
import PasswordResetPage from './pages/PasswordResetPage'
import AuthContext from './store/auth-context'

const App: React.FC = () => {
  const authCtx = React.useContext(AuthContext)
  const { authUser } = authCtx
  const baseRoute = authUser ? '/projects' : '/login'

  return (
    <Router>
      <ProjectsProvider>
        <BoardsProvider>
          <CategoriesProvider>
            <TagsProvider>
              <CharactersProvider>
                <NotesProvider>
                  <ModalContextProvider>
                    <ContextMenuProvider>
                      <DragProvider>
                        <Routes>
                          <Route element={<PrivateRoutes />}>
                            <Route
                              path="/projects"
                              element={<ProjectsPage />}
                            />
                            <Route
                              path="/projects/:projectId"
                              element={<ProjectShowPage />}
                            />
                          </Route>
                          <Route path={baseRoute} element={<AccessPage />} />
                          <Route
                            path="/password-reset/:token"
                            element={<PasswordResetPage />}
                          />
                          <Route
                            path="/*"
                            element={<Navigate to={baseRoute} />}
                          />
                        </Routes>
                      </DragProvider>
                    </ContextMenuProvider>
                  </ModalContextProvider>
                </NotesProvider>
              </CharactersProvider>
            </TagsProvider>
          </CategoriesProvider>
        </BoardsProvider>
      </ProjectsProvider>
    </Router>
  )
}

export default App
